import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import packageJson from '../package.json';

Sentry.init({
  dsn: 'https://8b7895e519ecfd2f0cf516aa83b02be8@sentry.prod.botman.pro/2',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  environment: environment.production ? 'prod' : 'dev',
  release: packageJson.version,
  tracePropagationTargets: ['localhost', /^https:\/\/botman\.pro/],
  sampleRate: 1,
  enabled: true,
  enableTracing: true,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.01
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
