import { State } from '../index';
import { createSelector } from '@ngrx/store';
import { UserState } from './user.reducers';

/* Get user state */
export const userState = (state: State) => state.user;

export const getUserSelector = createSelector(
  userState,
  (state: UserState) => state.user
);

export const getUserInfoSelector = createSelector(
  userState,
  (state: UserState) => state.userInfo
);

export const getUserSubscriptionSelector = createSelector(
  userState,
  (state: UserState) => state.userSubscription
);

export const getIsFreeSelector = createSelector(
  getUserSubscriptionSelector,
  (user) =>
    !!user?.currentPricePlan.free
);

export const getUserProfilesSelector = createSelector(
  userState,
  (state: UserState) => state.profiles
);

export const selectUserPricingInfoSelector = createSelector(
  userState,
  (state: UserState) => state.userPricingInfo
);

export const selectUserPackageSelector = createSelector(
  userState,
  (state: UserState) => state.userPackage
);
