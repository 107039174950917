import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { MeReferralInfo } from "../interfaces/me-referral-info.interface";
import { StatisticInterface } from "../interfaces/statistic.interface";
import { ReferralStatistics } from "../interfaces/referral-statistics.interface";
import { PayoutAvailability } from "../interfaces/payout-availability";
import { PayoutInterface } from "../interfaces/payout.interface";
import { IResponseInterface } from "../interfaces/iResponse.interface";

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  apiUrl = `${environment.apiUrl}/api/${environment.apiVersion}`;
  apiWithoutVersion = `${environment.apiUrl}/api`;

  constructor(private _http: HttpClient) {
  }

  getMeReferralInfo(): Observable<MeReferralInfo> {
    return this._http.get<MeReferralInfo>(this.apiUrl + `/affiliate/me/info/`);
  }

  getStatistics(): Observable<StatisticInterface[]> {
    return this._http.get<StatisticInterface[]>(this.apiUrl + '/affiliate/statistics')
  }

  createOrUpdateLink(body: StatisticInterface): Observable<StatisticInterface> {
    return this._http.put<StatisticInterface>(this.apiUrl + '/affiliate/links', body)
  }

  removeAffiliate(code: string): Observable<void> {
    return this._http.delete<void>(this.apiUrl + `/affiliate/${code}`);
  }

  getStatisticsByRange(start: string, end: string): Observable<ReferralStatistics[]> {
    return this._http.get<ReferralStatistics[]>(this.apiUrl + `/affiliate/statistics/summary/?start=${start}&end=${end}`)
  }

  getAllPayout(params: any): Observable<IResponseInterface<PayoutInterface[]>> {
    return this._http.get<IResponseInterface<PayoutInterface[]>>(`${this.apiWithoutVersion}/v2/payouts/`, {params});
  }

  getPayoutMethods(): Observable<PayoutAvailability> {
    return this._http.get<PayoutAvailability>(`${this.apiWithoutVersion}/payouts/availability/`)
  }

  createPayouts(body: Partial<PayoutInterface>): Observable<PayoutInterface> {
    return this._http.post<PayoutInterface>(`${this.apiWithoutVersion}/payouts/`, body)
  }
}
