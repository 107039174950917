import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addBroadcast,
  addBroadcastFailed,
  addBroadcastSuccess,
  addChannelAction,
  addFlowAction,
  addFlowFailedAction,
  addFlowSuccessAction,
  addTag,
  addTagFailed,
  addTagSuccess,
  broadcastSchedule,
  broadcastScheduleSuccess,
  cloneFlow,
  cloneFlowFailed,
  cloneFlowSuccess,
  createCustomField,
  createCustomFieldSuccess,
  createGlobalField,
  createGlobalFieldSuccess,
  createPayment,
  createPaymentSuccess,
  deleteChannelAction,
  deleteChannelFailedAction,
  deleteChannelSuccessAction,
  deleteCustomField,
  deleteCustomFieldSuccess,
  deleteFlow,
  deleteFlowFailed,
  deleteFlowSuccess,
  deleteGlobalField,
  deleteGlobalFieldSuccess,
  deletePayment,
  deletePaymentSuccess,
  getBroadcast,
  getBroadcastFailed,
  getBroadcastSuccess,
  getBusinessConnection,
  getBusinessConnectionSuccess,
  getChannelsAction,
  getChannelsFailedAction,
  getChannelsSuccessAction,
  getConnectedIntegrations,
  getConnectedIntegrationsSuccess,
  getCurrentFlow,
  getCurrentFlowFailed,
  getCurrentFlowSuccess,
  getCustomFields,
  getCustomFieldsSuccess,
  getFlowsAction,
  getFlowsFailedAction,
  getFlowsSuccessAction,
  getGlobalFields,
  getGlobalFieldsSuccess,
  getMeReferralInfoAction,
  getMeReferralInfoSuccessAction,
  getPayments,
  getPaymentsSuccess,
  getSegments,
  getSegmentsSuccess,
  getShared,
  getSharedSuccess,
  getStatisticAction,
  getStatisticSuccessAction,
  getTags,
  getTagsFailed,
  getTagsSuccess,
  removeTag,
  removeTagFailed,
  removeTagSuccess,
  updateBroadcastSuccess,
  updateCustomField,
  updateCustomFieldSuccess,
  updateFlow,
  updateFlowFailed,
  updateFlowSuccess,
  updateGlobalField,
  updateGlobalFieldSuccess,
  updatePayment,
  updatePaymentSuccess,
  updateTag,
  updateTagFailed,
  updateTagSuccess,
  updateTokenChannel,
  updateTokenChannelFailed,
  updateTokenChannelSuccess,
} from './app.actions';
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ChannelService } from '../../services/channel.service';
import { FlowService } from '../../services/flow.service';
import { Flow } from '../../interfaces/bot.interface';
import { SubscribersService } from '../../services/subscribers.service';
import { BroadcastService } from '../../services/broadcast.service';
import { SegmentService } from '../../services/segment.service';
import { TemplateService } from '../../services/template.service';
import { MeReferralInfo } from '../../interfaces/me-referral-info.interface';
import { AffiliateService } from '../../services/affiliate.service';
import { CustomFieldsService } from '../../services/custom-fields.service';
import {
  CustomFieldInterface,
  GlobalFieldInterface,
} from '../../interfaces/custom-field.interface';
import { PaymentService } from '../../services/payment.service';
import { PaymentInterface } from '../../interfaces/payment.interface';
import { IChannel } from '../../interfaces/channel.interface';
import { IntegrationsService } from '../../services/integrations.service';
import { AnalyticsService } from '../../services/analytics.service';
import calculateDailyDifference from '../../utils/calculate-daily-difference';
import { StatisticChannelInterface } from '../../interfaces/statistics.interface';
import { GlobalFieldsService } from '../../services/global-fields.service';
import { BusinessConnectionService } from '../../services/business-connection.service';
import { ChannelTypeEnum } from '../../data/channel-types';

@Injectable()
export class AppEffects {
  constructor(
    private _actions$: Actions,
    private _channelService: ChannelService,
    private _flowService: FlowService,
    private _subscribersService: SubscribersService,
    private _broadcastService: BroadcastService,
    private _segmentService: SegmentService,
    private _templateService: TemplateService,
    private _affiliateService: AffiliateService,
    private _customFieldsService: CustomFieldsService,
    private _globalFieldsService: GlobalFieldsService,
    private _paymentService: PaymentService,
    private _integrationsService: IntegrationsService,
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private businessService: BusinessConnectionService
  ) {}

  loadChannels$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getChannelsAction),
      mergeMap(() =>
        this._channelService.getAll().pipe(
          map((channels) => getChannelsSuccessAction({ channels })),
          catchError((err) => of(getChannelsFailedAction({ errors: err })))
        )
      )
    )
  );

  loadFlows$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getFlowsAction),
      switchMap(() =>
        this._flowService.getFlows().pipe(
          map((flows) => getFlowsSuccessAction({ flows })),
          catchError((err) => of(getFlowsFailedAction({ errors: err })))
        )
      )
    )
  );

  loadConnectedIntegrated = createEffect(() =>
    this._actions$.pipe(
      ofType(getConnectedIntegrations),
      switchMap(() =>
        this._integrationsService
          .getIntegrateConnected()
          .pipe(map((data) => getConnectedIntegrationsSuccess({ data })))
      )
    )
  );

  loadFlow$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getCurrentFlow),
      switchMap((payload: { id: string }) =>
        this._flowService.getFlowById(payload.id).pipe(
          map((flow) => getCurrentFlowSuccess({ flow })),
          catchError((err) => of(getCurrentFlowFailed({ errors: err })))
        )
      )
    )
  );

  deleteFlowById$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteFlow),
      switchMap((payload: { id: string }) =>
        this._flowService.deleteFlowById(payload.id).pipe(
          map(() => deleteFlowSuccess({ id: payload.id })),
          catchError((err) => of(deleteFlowFailed({ errors: err })))
        )
      )
    )
  );

  cloneFlowById$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cloneFlow),
      switchMap((payload: { id: string; channelId: string; name: string }) =>
        this._flowService
          .cloneFlowById(payload.id, payload.name, payload.channelId)
          .pipe(
            map((flow) => cloneFlowSuccess({ flow })),
            catchError((err) => of(cloneFlowFailed({ errors: err })))
          )
      )
    )
  );

  updateFlow$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateFlow),
      switchMap((payload: { flow: Flow }) =>
        this._flowService.updateFlow(payload.flow).pipe(
          map(() => updateFlowSuccess({ flow: payload.flow })),
          catchError((err) => of(updateFlowFailed({ errors: err })))
        )
      )
    )
  );

  addFlow$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addFlowAction),
      switchMap((payload) =>
        this._channelService.addBot(payload.flow).pipe(
          map((flow) =>
            addFlowSuccessAction({
              flow,
            })
          )
        )
      ),
      catchError((err) => of(addFlowFailedAction({ errors: err })))
    )
  );

  deleteChannel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteChannelAction),
      switchMap(({ channelId }) =>
        this._channelService.deleteChannelById(channelId).pipe(
          map(() => deleteChannelSuccessAction({ channelId })),
          catchError((err) => of(deleteChannelFailedAction({ errors: err })))
        )
      )
    )
  );

  loadTags = createEffect(() =>
    this._actions$.pipe(
      ofType(getTags),
      switchMap(() =>
        this._subscribersService.getTags().pipe(
          map((tags) => getTagsSuccess({ tags })),
          catchError((err) => of(getTagsFailed({ errors: err })))
        )
      )
    )
  );

  addTag$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addTag),
      switchMap((payload) =>
        this._subscribersService
          .createTag(payload.tag)
          .pipe(map((tag) => addTagSuccess({ tag })))
      ),
      catchError((err) => of(addTagFailed({ errors: err })))
    )
  );

  updateTag$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateTag),
      switchMap((payload) =>
        this._subscribersService
          .updateTag(payload.tag)
          .pipe(map((tag) => updateTagSuccess({ tag })))
      ),
      catchError((err) => of(updateTagFailed({ errors: err })))
    )
  );

  removeTag$ = createEffect(() =>
    this._actions$.pipe(
      ofType(removeTag),
      mergeMap(({ tagId }) =>
        this._subscribersService.removeTag(tagId).pipe(
          map(() => removeTagSuccess({ tagId })),
          catchError((err) => of(removeTagFailed({ errors: err })))
        )
      )
    )
  );

  addBroadcast$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addBroadcast),
      switchMap((payload) => {
        this._broadcastService.saving$.next(true);
        return this._broadcastService.createOrUpdate(payload.broadcast).pipe(
          map((broadcast) => {
            if (broadcast.id) {
              return updateBroadcastSuccess({ broadcast });
            } else {
              return addBroadcastSuccess({ broadcast });
            }
          }),
          finalize(() => this._broadcastService.saving$.next(false)),
          filter(() => payload.navigate),
          map(({ broadcast }) =>
            broadcastSchedule({ broadcastId: broadcast.id })
          )
        );
      }),
      catchError((err) => of(addBroadcastFailed({ errors: err })))
    )
  );

  loadBroadcast$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getBroadcast),
      switchMap((payload) =>
        this._broadcastService.getOne(payload.broadcastId).pipe(
          map((broadcast) => getBroadcastSuccess({ broadcast })),
          catchError((err) => of(getBroadcastFailed({ errors: err })))
        )
      )
    )
  );

  broadcastSchedule$ = createEffect(() =>
    this._actions$.pipe(
      ofType(broadcastSchedule),
      switchMap((payload) =>
        this._broadcastService
          .schedule(payload.broadcastId)
          .pipe(map(() => broadcastScheduleSuccess()))
      ),
      tap(() => this._router.navigate(['/app/broadcast']))
    )
  );

  getAllSegments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getSegments),
      switchMap(() =>
        this._segmentService
          .getAll()
          .pipe(map((segments) => getSegmentsSuccess({ segments })))
      )
    )
  );

  getAllShared$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getShared),
      switchMap(() =>
        this._templateService
          .getShared()
          .pipe(map((shared) => getSharedSuccess({ shared })))
      )
    )
  );

  getMeReferralInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getMeReferralInfoAction),
      switchMap(() => {
        return this._affiliateService.getMeReferralInfo().pipe(
          map((response: MeReferralInfo) =>
            getMeReferralInfoSuccessAction({ params: response })
          ),
          catchError(() => {
            return EMPTY;
          })
        );
      })
    )
  );

  loadCustomFields$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getCustomFields),
      switchMap(() =>
        this._customFieldsService.getCustomFields().pipe(
          map((res) => getCustomFieldsSuccess({ content: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createCustomField$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createCustomField),
      switchMap((payload: { customField: CustomFieldInterface }) =>
        this._customFieldsService.crateCustomField(payload.customField).pipe(
          map((res) => createCustomFieldSuccess({ customField: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteCustomField$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteCustomField),
      switchMap((payload: { id: string }) =>
        this._customFieldsService.deleteCustomField(payload.id).pipe(
          map(() => deleteCustomFieldSuccess({ id: payload.id })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateCustomField$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateCustomField),
      switchMap((payload: { customField: CustomFieldInterface }) =>
        this._customFieldsService.crateCustomField(payload.customField).pipe(
          map((res) => updateCustomFieldSuccess({ newCustomField: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadGlobalFields$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getGlobalFields),
      switchMap(() =>
        this._globalFieldsService.getGlobalFields().pipe(
          map((res) => getGlobalFieldsSuccess({ content: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createGlobalField$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createGlobalField),
      switchMap((payload: { globalField: GlobalFieldInterface }) =>
        this._globalFieldsService.crateGlobalField(payload.globalField).pipe(
          map((res) => createGlobalFieldSuccess({ globalField: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteGlobalField$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteGlobalField),
      switchMap((payload: { id: string }) =>
        this._globalFieldsService.deleteGlobalField(payload.id).pipe(
          map(() => deleteGlobalFieldSuccess({ id: payload.id })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateGlobalField$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateGlobalField),
      switchMap((payload: { globalField: GlobalFieldInterface }) =>
        this._globalFieldsService.crateGlobalField(payload.globalField).pipe(
          map((res) => updateGlobalFieldSuccess({ newGlobalField: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getPayments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getPayments),
      switchMap(() =>
        this._paymentService.getPayments().pipe(
          map((payments) => getPaymentsSuccess({ payments })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deletePayment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deletePayment),
      switchMap((payload: { ids: string[] }) =>
        forkJoin(
          payload.ids.map((id) => this._paymentService.deletePayment(id))
        ).pipe(
          map(() => deletePaymentSuccess({ ids: payload.ids })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createPayment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createPayment),
      switchMap((payload: { payment: PaymentInterface }) =>
        this._paymentService.createPayment(payload.payment).pipe(
          map((res) => createPaymentSuccess({ newPayment: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updatePayment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updatePayment),
      switchMap((payload: { payment: PaymentInterface }) =>
        this._paymentService.updatePayment(payload.payment).pipe(
          map((res) => updatePaymentSuccess({ newPayment: res })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateTokenChannel),
      switchMap((payload: { id: string; token: string; typeKey: string }) =>
        this._channelService
          .updateToken(payload.id, {
            token: payload.token,
            type: payload.typeKey,
          })
          .pipe(
            map((channel) => updateTokenChannelSuccess({ channel })),
            catchError((error) => of(updateTokenChannelFailed({ error })))
          )
      )
    )
  );

  addFlowOnChannelAddAction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addChannelAction),
      mergeMap((payload: { channel: IChannel }) => {
        const flow: any = {
          name: '@' + payload.channel.adapter.username,
          channelId: payload.channel.id,
        };
        return this._channelService
          .addBot(flow)
          .pipe(map((flow) => addFlowSuccessAction({ flow })));
      })
    )
  );

  addFlowSuccessActionOpenEdit$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(addFlowSuccessAction),
        tap((payload: { flow: Flow }) => {
          if (payload.flow.type !== ChannelTypeEnum.BROADCAST) {
            this._router.navigate([
              `/app/flow/builder/${payload.flow.id}/edit`,
            ]);
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  getStatisticAction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getStatisticAction),
      switchMap(({ channelAdapterIds }: { channelAdapterIds: string[] }) => {
        let statistics: StatisticChannelInterface = {};
        return forkJoin(
          channelAdapterIds.map((adapterId) =>
            this._analyticsService
              .getUserStatistic({
                adapterId: adapterId,
              })
              .pipe(
                tap((statistic) => {
                  statistics[adapterId] = calculateDailyDifference(statistic);
                })
              )
          )
        ).pipe(
          map(() => getStatisticSuccessAction({ statistic: statistics })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  getBusinessConnection$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getBusinessConnection),
      switchMap(() =>
        this.businessService.getBusinessConnections().pipe(
          map((data) => getBusinessConnectionSuccess({ data })),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
