import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable()
export class RoleCheckerGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private jwtHelper: JwtHelperService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.jwtHelper.decodeToken()['authorities'].includes(route.data['role']);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }
}
