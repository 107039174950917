import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { IResponseInterface } from '../interfaces/iResponse.interface';
import {
  Tag,
  SubscribersInterface,
  UserSearchFilter,
  UserBody,
  IImportHistory,
} from '../interfaces/subscribers.interface';
import { IParams } from '../interfaces/iParams';
import { CreateCustomFieldInterface } from '../interfaces/custom-field.interface';
import { FieldInterface } from '../interfaces/field.interface';

@Injectable({
  providedIn: 'root',
})
export class SubscribersService {
  openConditionDialogAction: EventEmitter<any> = new EventEmitter();
  constructor(private _http: HttpClient) {}

  getUsers(
    params: IParams | any,
    body: UserSearchFilter | any,
    search = '',
    operator: string
  ): Observable<IResponseInterface<SubscribersInterface[]>> {
    return this._http.post<IResponseInterface<SubscribersInterface[]>>(
      `${env.apiUrl}/api/${env.apiVersion}/users/`,
      this.getFilterData(body, search, operator),
      {
        params,
      }
    );
  }

  getUsersV2(
    params: IParams | any,
    body: any
  ): Observable<IResponseInterface<SubscribersInterface[]>> {
    return this._http.post<IResponseInterface<SubscribersInterface[]>>(
      `${env.apiUrl}/api/${env.apiVersion}/users/bulk/search`,
      body,
      {
        params,
      }
    );
  }

  getUserById(id: string): Observable<SubscribersInterface> {
    return this._http.get<SubscribersInterface>(
      `${env.apiUrl}/api/${env.apiVersion}/users/${id}/details`
    );
  }

  getTags(): Observable<any> {
    return this._http.get(`${env.apiUrl}/api/${env.apiVersion}/tags/`);
  }

  removeTag(tagId: string): Observable<any> {
    return this._http.delete(
      `${env.apiUrl}/api/${env.apiVersion}/tags/${tagId}`
    );
  }

  createTag(tag: Tag): Observable<any> {
    return this._http.post(`${env.apiUrl}/api/${env.apiVersion}/tags/`, tag);
  }

  updateTag(tag: Tag): Observable<any> {
    return this._http.post(`${env.apiUrl}/api/${env.apiVersion}/tags/`, tag);
  }

  userTagAdd(id: string, tagId: string) {
    return this._http.post(
      `${env.apiUrl}/api/${env.apiVersion}/users/${id}/tags`,
      {},
      { params: { tagId } }
    );
  }

  userTagRemove(id: string, tagId: string) {
    return this._http.delete(
      `${env.apiUrl}/api/${env.apiVersion}/users/${id}/tags`,
      { params: { tagId } }
    );
  }

  deleteUserById(id: string) {
    return this._http.delete(`${env.apiUrl}/api/${env.apiVersion}/users/${id}`);
  }

  getUsersBulk(
    params: IParams | any,
    body: any
  ): Observable<IResponseInterface<SubscribersInterface[]>> {
    return this._http.post<IResponseInterface<SubscribersInterface[]>>(
      `${env.apiUrl}/api/${env.apiVersion}/users/bulk/`,
      body,
      { params }
    );
  }

  public getFilterData(
    body: UserSearchFilter | any,
    search = '',
    operator: string
  ): any {
    return {
      search: search.trim(),
      filter: {
        operator: operator,
        groups: [
          {
            operator: operator,
            items: body,
          },
        ],
      },
    };
  }

  public export(data: UserBody): Observable<any> {
    return this._http.post(
      `${env.apiUrl}/api/${env.apiVersion}/users/bulk/export`,
      data,
      { observe: 'response', responseType: 'blob' }
    );
  }

  public import(data: any): Observable<any> {
    return this._http.post(
      `${env.apiUrl}/api/${env.apiVersion}/users/imports/`,
      data
    );
  }

  public importHistory(
    queryParams: any = {}
  ): Observable<IResponseInterface<IImportHistory[]>> {
    const params = new HttpParams({ fromObject: queryParams });
    return this._http.get<IResponseInterface<IImportHistory[]>>(
      `${env.apiUrl}/api/${env.apiVersion}/users/imports/`,
      { params }
    );
  }

  createCustomFields(
    id: string,
    body: CreateCustomFieldInterface
  ): Observable<null> {
    return this._http.post<null>(
      `${env.apiUrl}/api/${env.apiVersion}/users/${id}/customFields`,
      body
    );
  }

  deleteCustomField(id: string, fieldId: string): Observable<null> {
    return this._http.delete<null>(
      `${env.apiUrl}/api/${env.apiVersion}/users/${id}/customFields?fieldId=${fieldId}`
    );
  }

  createField(id: string, field: FieldInterface): Observable<null> {
    return this._http.post<null>(
      `${env.apiUrl}/api/${env.apiVersion}/users/${id}/fields`,
      field
    );
  }

  deleteField(id: string, fieldName: string): Observable<null> {
    return this._http.delete<null>(
      `${env.apiUrl}/api/${env.apiVersion}/users/${id}/fields?fieldName=${fieldName}`
    );
  }
}
