import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  LabelTemplateDirective,
  NotFoundTemplateDirective,
  OptionTemplateDirective,
} from '../../directives/template.directives';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChannelTypeEnum } from '../../../../core/data/channel-types';

export interface IUiSelect {
  label: string;
  value: any;
  type: ChannelTypeEnum|string;
  icon?: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectComponent),
      multi: true,
    },
  ],
})
export class UiSelectComponent implements ControlValueAccessor, OnChanges {
  @Input() items: any[] = [];
  @Input() placeholder: string = '';
  @Input() label!: string;
  @Input() valueKey!: string;
  @Input() labelKey!: string;
  @Input() size: 'extra-small' | 'default' = 'default';
  @Input()
  tooltip!: string;
  @Input() disable: boolean = false;
  @Output() changed = new EventEmitter<any>();

  @ContentChild(OptionTemplateDirective, { read: TemplateRef })
  optionTemplate!: TemplateRef<any>;
  @ContentChild(LabelTemplateDirective, { read: TemplateRef })
  labelTemplate!: TemplateRef<any>;
  @ContentChild(NotFoundTemplateDirective, { read: TemplateRef })
  notFoundTemplate!: TemplateRef<any>;

  selected: IUiSelect|null = null;
  isOpen: boolean = false;
  value = '';
  @HostBinding('class.ui-select-xs') select_xs: boolean = false;

  constructor(protected _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('size')) {
      this.select_xs = this.size === 'extra-small';
    }
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('items')) {
      if (this.items?.length && this.valueKey) {
        this.selected = this.items.find((item) =>
          this.valueKey
            ? item[this.valueKey] === this.value
            : item.value === this.value
        );
      }
    }
    this._cdr.markForCheck();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  onSelect(item: IUiSelect|null): void {
    this.selected = item;
    let value = '';
    if (this.selected) {
      value = this.valueKey
        ? this.selected[this.valueKey]
        : this.selected.value;
    }

    this.value = value;
    this.onChange(value);
    this.changed.emit(value);
    this._cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this._cdr.detectChanges();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this._cdr.detectChanges();
  }

  writeValue(value: any): void {
    if (value instanceof Object) {
      this.selected = value;
    } else {
      this.value = value;
      if (this.items?.length) {
        this.selected = this.items.find((item) =>
          this.valueKey ? item[this.valueKey] === value : item.value === value
        );
      }
    }
    this._cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean) {
    this.disable = isDisabled;
  }

  toggleDropdown(): void {
    if (!this.disable) {
      this.isOpen = !this.isOpen;
      this._cdr.detectChanges();
    }
  }
}
