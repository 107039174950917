import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as env } from '../../../environments/environment';
import { IResponseInterface } from '../interfaces/iResponse.interface';
import { IPayment, PaymentInterface } from '../interfaces/payment.interface';
import { IParams } from '../interfaces/iParams';
import { HistoryPaymentInterface } from '../interfaces/history.payment.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private _apiUrl = `${env.apiUrl}/api/${env.apiVersion}/payments`;
  private _apiUrlIntegration = `${env.apiUrl}/api/${env.apiVersion}/integrations/payments`;

  constructor(private _http: HttpClient) {}

  getAll(queryParams: any = {}): Observable<IResponseInterface<IPayment[]>> {
    const params = new HttpParams({ fromObject: queryParams });

    return this._http.get<IResponseInterface<IPayment[]>>(`${this._apiUrl}`, {
      params,
    });
  }

  getPaymentById(id: string): Observable<PaymentInterface> {
    return this._http.get<PaymentInterface>(
      `${this._apiUrlIntegration}/pages/${id}`
    );
  }

  updatePayment(payment: PaymentInterface): Observable<PaymentInterface> {
    return this._http.put<PaymentInterface>(
      `${this._apiUrlIntegration}/pages/`,
      payment
    );
  }

  createPayment(payment: PaymentInterface): Observable<PaymentInterface> {
    return this._http.post<PaymentInterface>(
      `${this._apiUrlIntegration}/pages/`,
      payment
    );
  }

  deletePayment(id: string): Observable<void> {
    return this._http.delete<void>(`${this._apiUrlIntegration}/pages/${id}`);
  }

  getPayments(): Observable<PaymentInterface[]> {
    return this._http.get<PaymentInterface[]>(
      `${this._apiUrlIntegration}/pages/all`
    );
  }

  getPaymentAll(
    queryParams: IParams
  ): Observable<IResponseInterface<PaymentInterface[]>> {
    // @ts-ignore
    const params = new HttpParams({ fromObject: queryParams });

    return this._http.get<IResponseInterface<PaymentInterface[]>>(
      `${this._apiUrlIntegration}/pages/`,
      {
        params,
      }
    );
  }

  getIntegrationPayments(
    params: any = {}
  ): Observable<IResponseInterface<HistoryPaymentInterface[]>> {
    return this._http.get<IResponseInterface<HistoryPaymentInterface[]>>(
      `${this._apiUrlIntegration}/`,
      { params }
    );
  }
}
