import { Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CheckForUpdateService } from './core/services/pwa/check-for-update.service';
import { LogUpdateService } from './core/services/pwa/log-update.service';
import { HandleUnrecoverableStateService } from './core/services/pwa/handle-unrecoverable-state.service';
import { PromptUpdateService } from './core/services/pwa/prompt-update.service';
import packageJson from '../../package.json';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public version: string = packageJson.version;

  constructor(
    public el: ElementRef,
    public translateService: TranslateService,
    public checkForUpdateService: CheckForUpdateService,
    public logUpdateService: LogUpdateService,
    public unrecoverableStateService: HandleUnrecoverableStateService,
    public promptUpdateService: PromptUpdateService
  ) {
    this.translateService.use('ru');
  }

  ngOnInit() {
    this.translateService.onLangChange
      .pipe(
        startWith(() => {
          this.updateLanguage();
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.updateLanguage();
      });
  }

  updateLanguage(): void {
    if (this.translateService?.currentLang) {
      const lang = document.createAttribute('lang');
      lang.value = this.translateService.currentLang;
      this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(
        lang
      );
    }
  }
}
