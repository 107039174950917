export const environment = {
  production: true,
  dev: false,
  apiUrl: window['env']['apiUrl'] || 'https://gate.staging.alb.botman.pro',
  apiAuth: window['env']['apiAuth'] || 'https://auth.botman.pro',
  wsUrl: window['env']['wsUrl'] || 'https://ws.botman.pro/ws',
  apiVersion: 'v1',
  metrikaId: '94780607',
  miniLandingDomain: window['env']['mlDomain'] || 'https://btmn.io'
};
