import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SegmentInterface } from '../interfaces/segment.interface';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private _apiUrl = `${env.apiUrl}/api/${env.apiVersion}/segments/`;

  constructor(private _http: HttpClient) {}

  public getAll(): Observable<SegmentInterface[]> {
    return this._http.get<SegmentInterface[]>(this._apiUrl);
  }

  public createOrUpdate(segment: any): Observable<any> {
    return this._http.post(this._apiUrl, segment);
  }

  public getOne(segmentId: any): Observable<any> {
    return this._http.get(`${this._apiUrl}${segmentId}`);
  }

  public delete(segmentId: string): Observable<any> {
    return this._http.delete(`${this._apiUrl}${segmentId}`);
  }

  public getUsers(segmentId: any, queryParams: any = {}): Observable<any> {
    const params = new HttpParams({ fromObject: queryParams });
    return this._http.post(`${this._apiUrl}${segmentId}/users`, {}, { params });
  }
}
