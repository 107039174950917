import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers: { [key: string]: string } = {};
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('access_token_chatbotx');

    if (accessToken && !req.headers.has('authorization')) {
      headers['authorization'] = `Bearer ${accessToken}`;
    }

    if (userId) {
      headers['X-Agent-Profile-Id'] = userId;
    }

    const apiReq = req.clone({ setHeaders: headers });
    return next.handle(apiReq).pipe(catchError((x) => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (
      (!err.url?.includes('2fa') && err.status === 401) ||
      err.status === 403
    ) {
      const router = this.injector.get(Router);
      router.navigate(['/login']);
      return of(err.message);
    }
    return throwError(err);
  }
}
