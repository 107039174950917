import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import {
  ITgChannelRequest,
  IVkChannelRequest,
} from '../interfaces/add-channel-request';
import { IChannel } from '../interfaces/channel.interface';
import { Flow } from '../interfaces/bot.interface';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  constructor(private _http: HttpClient) {}

  getAll(): Observable<IChannel[]> {
    return this._http.get<IChannel[]>(
      `${env.apiUrl}/api/${env.apiVersion}/channels/`
    );
  }

  add(channel: ITgChannelRequest | IVkChannelRequest): Observable<IChannel> {
    return this._http.put<IChannel>(
      `${env.apiUrl}/api/${env.apiVersion}/channels/`,
      channel
    );
  }

  getById(channelId: string): Observable<IChannel> {
    return this._http.get<IChannel>(
      `${env.apiUrl}/api/${env.apiVersion}/channels/${channelId}`
    );
  }

  updateToken(
    id: string,
    body: {
      token: string;
      type: string;
    }
  ): Observable<IChannel> {
    return this._http.post<IChannel>(
      `${env.apiUrl}/api/${env.apiVersion}/channels/${id}`,
      body
    );
  }

  addBot(flow: Flow): Observable<Flow> {
    return this._http.put<Flow>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/`,
      flow
    );
  }

  deleteChannelById(id: string): Observable<any> {
    return this._http.delete(
      `${env.apiUrl}/api/${env.apiVersion}/channels/${id}`
    );
  }
}
