export enum ChannelTypeEnum {
  VKONTAKTE = 'VKONTAKTE',
  TELEGRAM = 'TELEGRAM',
  INSTAGRAM = 'INSTAGRAM',
  ALL = 'ALL',
  BROADCAST = 'broadcast',
}

export const ChannelTypes: IChannelType[] = [
  {
    type: ChannelTypeEnum.TELEGRAM,
    active: true,
  },
  {
    type: ChannelTypeEnum.VKONTAKTE,
    active: true,
  },
  {
    type: ChannelTypeEnum.INSTAGRAM,
    active: false,
  },
  {
    type: ChannelTypeEnum.ALL,
    active: false,
  },
];

export interface IChannelType {
  type: ChannelTypeEnum | string;
  active: boolean;
}
