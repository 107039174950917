import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ShareBotDialogComponent } from '../../modules/panel/components/share-bot-dialog/share-bot-dialog.component';
import { Flow } from '../interfaces/bot.interface';
import { AddBotComponent } from '../../modules/panel/pages/home/components/add-bot/add-bot.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private _dialog: MatDialog, private router: Router) {}

  addBotDialog(): void {
    this._dialog.open(AddBotComponent);
  }

  openShareBotDialog(flow: Flow) {
    this._dialog.open(ShareBotDialogComponent, {
      width: '390px',
      data: flow,
    });
  }
}
