import { Analytics } from '../interfaces/analytics.interface';

export default function calculateDailyDifference(
  data: Analytics
): Analytics<number> {
  const output: Analytics<number> = {
    unsubscribesGraph: 0,
    totalSubscribersGraph: 0,
    newSubscribersGraph: 0,
    totalActiveSubscribersGraph: 0,
  };

  for (const property in data) {
    if (data[property]) {
      output[property] = Object.values(data[property])[1];
    }
  }

  return output;
}
