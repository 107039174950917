<form [formGroup]="form" class="bot-form" (submit)="(form.valid || !channels.length) && submit()">
  <div class="d-flex justify-content-end">
    <span class="icon icon-close bot-form__close" mat-dialog-close></span>
  </div>

  <div class="bot-form__title">
    <span class="bot-form__title_new">{{
      'AddBotComponent.TITLE1' | translate
    }}</span>
    <span class="bot-form__title_bot">{{
      'AddBotComponent.TITLE2' | translate
    }}</span>
  </div>

  <div class="bot-form__content">
    <div class="form-control">
      <app-select
        [placeholder]="'AddBotComponent.SELECT_A_CHANNEL' | translate"
        labelKey="type"
        valueKey="id"
        formControlName="channelId"
        [items]="channels"
        [label]="'AddBotComponent.CHOOSE_CHANNEL' | translate"
      >
        <ng-template appOptionTmp let-item="item">
          <div class="channel-type-item d-flex align-items-center">
            <img src="./assets/icons/{{ item.type }}.svg" alt="" />
            <span class="channel-type-item__text">{{ item.adapter.name }}</span>
          </div>
        </ng-template>

        <ng-template appLabelTmp let-item="item">
          <div class="channel-type-item p-0 d-flex align-items-center">
            <img src="./assets/icons/{{ item.type }}.svg" alt="" />
            <span class="channel-type-item__text">{{ item.adapter.name }}</span>
          </div>
        </ng-template>
      </app-select>
    </div>

    <!-- NAME -->
    <div class="form-control">
      <app-input
        class="w-100"
        type="text"
        formControlName="name"
        [label]="'AddBotComponent.BOT_NAME' | translate"
        [placeholder]="'AddBotComponent.ENTER_NAME' | translate"
        [class.ng-error]="form.hasError('serverError')"
      >
      </app-input>

      <div class="d-flex justify-content-between">
        <div class="form-control__hint">
          <span class="icon icon-check-circle"></span>
          <span>{{ 'AddBotComponent.CONVENIENCE' | translate }}</span>
        </div>
        <div class="form-control__hint">
          {{ form.get('name')?.value.length }}/{{ nameMaxLength }}
        </div>
      </div>
      <div
        class="form-control__error"
        *ngIf="form.get('name')?.hasError('maxlength')"
      >
        <span class="icon icon-danger-triangle"></span>
        {{ 'AddBotComponent.LONG_NAME_ERROR' | translate }}
      </div>
      <div class="form-control__error" *ngIf="form.hasError('serverError')">
        <span class="icon icon-danger-triangle"></span>
        {{ form.getError('serverError') }}
      </div>
    </div>
  </div>

  <div class="bot-form__footer">
    <button
      class="btn btn-primary btn-sm w-100 add-channel-btn"
      [disabled]="channels.length && form.invalid"
    >
      {{ (channels.length ? 'AddBotComponent.CREATE_BOT' : 'AddBotComponent.ADD_SOCIAL_NET') | translate }}
    </button>
    <button class="btn btn-tertiary btn-sm w-100" mat-dialog-close>
      {{ 'AddChannelComponent.CANCEL' | translate }}
    </button>
  </div>
</form>
