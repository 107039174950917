import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { cloneDeep } from 'lodash';

import { State } from '../../../../core/store';
import {
  updateFlow,
  updateFlowSuccess,
} from '../../../../core/store/app/app.actions';
import { Flow } from '../../../../core/interfaces/bot.interface';
import { FlowService } from '../../../../core/services/flow.service';

@Component({
  selector: 'app-share-bot-dialog',
  templateUrl: './share-bot-dialog.component.html',
  styleUrls: ['./share-bot-dialog.component.scss'],
})
export class ShareBotDialogComponent implements OnInit {
  public link: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Flow,
    private matDialogRef: MatDialogRef<ShareBotDialogComponent>,
    private _store: Store<State>,
    private flowService: FlowService
  ) {}

  ngOnInit(): void {
    this.generateLink(this.data.code);
  }

  private generateLink(code: string | undefined): void {
    this.link = window.location.origin + '/app/template/view/' + code;
  }

  public async copyLink() {
    await navigator.clipboard.writeText(this.link);
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public updateFlow(event: boolean): void {
    const flow = cloneDeep(this.data);
    if (flow) {
      flow.sharedCloningEnabled = event;
      this._store.dispatch(updateFlow({ flow }));
    }
  }

  refreshLink(flowId: string): void {
    this.flowService
      .regenerateCode(flowId)
      .pipe(first())
      .subscribe((flow) => {
        this._store.dispatch(updateFlowSuccess({ flow }));
        this.generateLink(flow.code);
      });
  }
}
