import { createAction, props } from '@ngrx/store';
import { UserInterface } from '../../interfaces/user.interface';
import { UserInfoInterface } from '../../interfaces/user-info.interface';
import { UserSubscription } from '../../interfaces/user-subscription';
import { ProfileInterface } from '../../interfaces/agent.interface';
import { UserPackage } from '../../interfaces/user.package';
import { UserPricingInfoInterface } from '../../interfaces/user.info';

export enum UserActionType {
  GetUser = '[User] Load user',
  GetUserSuccess = '[User] Load user success',
  UpdateUser = '[User] Update user',
  UpdateUserSuccess = '[User] Update user success',
  UpdateUserFailure = '[User] Update user failure',
  GetUserInfo = '[User] Get user info',
  GetUserInfoSuccess = '[User] Get user info success',
  GetUserInfoFailure = '[User] Get user info failure',
  GetUserSubscription = '[User] Get user subscription',
  GetUserSubscriptionSuccess = '[User] Get user subscription success',
  GetUserSubscriptionFailure = '[User] Get user subscription failure',

  GetProfiles = '[User] Load profiles',
  GetProfilesSuccess = '[User] Load profiles success',

  GetUserPricingInfo = '[App] GetUserPricingInfo',
  GetUserPricingInfoSuccess = '[App] GetUserPricingInfo success',
  GetUserPackage = '[App] GetUserPackage',
  GetUserPackageSuccess = '[App] GetUserPackage success',

  ResetUserState = '[App] Reset user state',
}

export const getUser = createAction(UserActionType.GetUser);

export const getUserSuccess = createAction(
  UserActionType.GetUserSuccess,
  props<{ user: UserInterface }>()
);

export const updateUser = createAction(
  UserActionType.UpdateUser,
  props<{ user: UserInterface }>()
);

export const updateUserSuccess = createAction(
  UserActionType.UpdateUserSuccess,
  props<{ user: UserInterface }>()
);

export const updateUserFailure = createAction(
  UserActionType.UpdateUserSuccess,
  props<{ error: any }>()
);

export const getUserInfo = createAction(UserActionType.GetUserInfo);

export const getUserInfoSuccess = createAction(
  UserActionType.GetUserInfoSuccess,
  props<{ userInfo: UserInfoInterface }>()
);

export const getUserInfoFailure = createAction(
  UserActionType.GetUserInfoFailure,
  props<{ errors: any }>()
);

export const getUserSubscription = createAction(
  UserActionType.GetUserSubscription
);

export const getUserSubscriptionSuccess = createAction(
  UserActionType.GetUserSubscriptionSuccess,
  props<{ userSubscription: UserSubscription }>()
);
export const getUserSubscriptionFailure = createAction(
  UserActionType.GetUserSubscriptionFailure,
  props<{ error: any }>()
);

export const getProfile = createAction(UserActionType.GetProfiles);
export const getProfileSuccess = createAction(
  UserActionType.GetProfilesSuccess,
  props<{ profiles: ProfileInterface[] }>()
);

export const getUserInfoAction = createAction(
  UserActionType.GetUserPricingInfo
);

export const getUserInfoSuccessAction = createAction(
  UserActionType.GetUserPricingInfoSuccess,
  props<{ userInfo: UserPricingInfoInterface }>()
);

export const getUserPackageAction = createAction(UserActionType.GetUserPackage);

export const getUserPackageSuccessAction = createAction(
  UserActionType.GetUserPackageSuccess,
  props<{ userPackage: UserPackage }>()
);

export const resetUserStateAction = createAction(UserActionType.ResetUserState);
