import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../../../../core/store';
import { MatDialogRef } from '@angular/material/dialog';
import { addFlowAction } from '../../../../../../core/store/app/app.actions';
import { getChannels } from '../../../../../../core/store/app/app.selectors';
import { first } from 'rxjs';
import { IChannel } from '../../../../../../core/interfaces/channel.interface';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-bot',
  templateUrl: './add-bot.component.html',
  styleUrls: ['./add-bot.component.scss'],
})
export class AddBotComponent implements OnInit {
  form!: FormGroup;
  channels: IChannel[] = [];
  nameMaxLength: number = 50;

  constructor(
    private _appStore: Store<State>,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<AddBotComponent>
  ) {}

  ngOnInit(): void {
    this._initForm();
    this._getChannels();
  }

  submit(): void {
    if (!this.channels.length) {
      this._dialogRef.close();
      // this.modalService.openAddChannel();
      return;
    }

    this._appStore.dispatch(
      addFlowAction({ flow: this.form.value, redirectToBuilder: true })
    );
    this._dialogRef.close(this.form.value);
  }

  private _initForm(): void {
    this.form = this._fb.group({
      channelId: [null, Validators.required],
      name: [
        '',
        [Validators.required, Validators.maxLength(this.nameMaxLength)],
      ],
    });
  }

  private _getChannels(): void {
    this._appStore
      .select(getChannels)
      .pipe(first())
      .subscribe((res) => {
        this.channels = res;
        if (res.length) {
          this.form.controls['channelId'].setValue(res[0].id);
        }
      });
  }

  addNewChanel(): void {
    this._dialogRef.close();
    // this.modalService.openAddChannel();
  }
}
