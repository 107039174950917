import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BusinessConnection } from '../interfaces/business-connection.interface';

@Injectable({
  providedIn: 'root',
})
export class BusinessConnectionService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = environment.apiUrl;

  getBusinessConnections(): Observable<BusinessConnection[]> {
    return this.http.get<BusinessConnection[]>(
      this.baseUrl + '/api/v1/tg/businessConnections/'
    );
  }
}
