import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { GlobalFieldInterface } from '../interfaces/custom-field.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalFieldsService {
  private _apiUrl = `${environment.apiUrl}/api/${environment.apiVersion}`;

  constructor(private _http: HttpClient) {}

  getGlobalFields(): Observable<GlobalFieldInterface[]> {
    return this._http.get<GlobalFieldInterface[]>(
      this._apiUrl + '/globalFields/'
    );
  }

  crateGlobalField(
    body: GlobalFieldInterface
  ): Observable<GlobalFieldInterface> {
    return this._http.put<GlobalFieldInterface>(
      this._apiUrl + '/globalFields/',
      body
    );
  }

  deleteGlobalField(id: string): Observable<void> {
    return this._http.delete<void>(`${this._apiUrl}/globalFields/${id}`);
  }
}
