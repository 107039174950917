import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ITokenResponse } from '../interfaces/token-response.interface';
import { UserRegisterRequest } from '../interfaces/user-register-request';

export const ACCESS_TOKEN_KEY = 'access_token_chatbotx';

@Injectable({
  providedIn: 'root',
})
export class EntryService {
  apiUrl = '/api/' + env.apiVersion;
  private authHeader: string = window.btoa('client:7K3sytPhZ4Qv9EZb');
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + this.authHeader,
    }),
  };
  private authTokenGetUrl = env.apiAuth + '/oauth/token';

  constructor(
    private _http: HttpClient,
    private _cookieService: CookieService,
    private _jwtHelper: JwtHelperService,
    private _router: Router
  ) {}

  public register(userInfo: UserRegisterRequest): Observable<any> {
    userInfo.email = userInfo.email?.toLowerCase();
    if (this._cookieService.check('ref')) {
      userInfo.refCode = this._cookieService.get('ref');
    }
    userInfo.properties = {
      referer: this._cookieService.get('original_referer'),
      utm_source: this._cookieService.get('utm_source'),
      utm_medium: this._cookieService.get('utm_medium'),
      utm_campaign: this._cookieService.get('utm_campaign'),
      utm_term: this._cookieService.get('utm_term'),
      utm_content: this._cookieService.get('utm_content'),
      browser_lang:
        (navigator.languages && navigator.languages[0]) || navigator.language,
    };

    return this._http.post(
      `${env.apiAuth}/api/${env.apiVersion}/user/registration`,
      userInfo
    );
  }

  public login(
    userInfo: any,
    justRegistered = false
  ): Observable<ITokenResponse> {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', userInfo.username.toLowerCase())
      .set('password', userInfo.password);

    return this._http
      .post<ITokenResponse>(
        this.authTokenGetUrl,
        body.toString(),
        this.httpOptions
      )
      .pipe(
        tap((res) => {
          this.manualLogin(res['access_token'], justRegistered);
          const decoded = this._jwtHelper.decodeToken();
          const tokenAuthorities = decoded['authorities'] as string[];

          if (tokenAuthorities && tokenAuthorities.includes('PRE_AUTH')) {
            this._router.navigate(['/entry/two-factor-authentication']);
          } else {
            this._router.navigate(['/']);
          }
        })
      );
  }

  public forgotPassword(email: string): Observable<any> {
    return this._http.post(
      `${env.apiAuth}/api/${env.apiVersion}/user/password/forgot/`,
      { email }
    );
  }

  public changePassword(passwd: any): Observable<any> {
    return this._http.post(
      `${env.apiAuth}/api/${env.apiVersion}/user/password/update/`,
      passwd
    );
  }

  public delete(): Observable<any> {
    return this._http.delete(`${env.apiAuth}/api/${env.apiVersion}/user/`);
  }

  public resetPassword(token: string, pass: string) {
    return this._http.post(
      `${env.apiAuth}/api/${env.apiVersion}/user/password/reset/`,
      {
        token: token,
        pass: pass,
      },
      { responseType: 'text' }
    );
  }

  public manualLogin(token: string, justRegistered: boolean = false) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    const carrotQuest = window['carrotquest'];
    let decoded = this._jwtHelper.decodeToken(token);
    carrotQuest.auth(decoded['user_id'], decoded['user_id_hash']);
    if (justRegistered) {
      carrotQuest.track('$registered', { $email: decoded['user_name'] });
    }
    carrotQuest.track('$authorized', { $email: decoded['user_name'] });
  }

  twoFactorLogin(code: string): Observable<ITokenResponse> {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    const body = new HttpParams()
      .set('grant_type', 'tfa')
      .set('tfa_token', token || '')
      .set('tfa_code', code);

    return this._http
      .post<ITokenResponse>(this.authTokenGetUrl, body, this.httpOptions)
      .pipe(
        tap((res) => {
          this.manualLogin(res['access_token']);
          this._router.navigate(['/']);
        })
      );
  }
}
