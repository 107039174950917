import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ICampaigns,
  IFunnel,
  IFunnelStatus,
  IGetCourseIntegration,
} from '../interfaces/integration.models';
import { IntegrationConnectInterface } from '../interfaces/integrationConnect.interface';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  private _apiUrl = `${env.apiUrl}/api/${env.apiVersion}/integrations/`;

  constructor(private _http: HttpClient) {}

  createIntegrate(params: any): Observable<IGetCourseIntegration> {
    return this._http.post<IGetCourseIntegration>(this._apiUrl, params);
  }
  updateIntegrate(params: any): Observable<IGetCourseIntegration> {
    return this._http.put<IGetCourseIntegration>(this._apiUrl, params);
  }
  getIntegrateById(id: any): Observable<IGetCourseIntegration> {
    return this._http.get<IGetCourseIntegration>(this._apiUrl + id);
  }
  deleteIntegrateById(id: any): Observable<IGetCourseIntegration> {
    return this._http.delete<IGetCourseIntegration>(this._apiUrl + id);
  }

  getIntegrateConnected(): Observable<IGetCourseIntegration[]> {
    return this._http.get<IGetCourseIntegration[]>(this._apiUrl + 'connected');
  }
  getCourseIntegrateSearch(type: string): Observable<IGetCourseIntegration> {
    return this._http.get<IGetCourseIntegration>(
      this._apiUrl + 'search?type=' + type
    );
  }

  connectGoogleSheets(): Observable<any> {
    return this._http.get<any>(this._apiUrl + 'googleSheets/connectUrl');
  }

  getGoogleSheets(id: string): Observable<any> {
    return this._http.get<any>(
      this._apiUrl + `googleSheets/spreadsheets/${id}/sheets`
    );
  }

  getGoogleSheetColumnHeaders(id: string, sheetId: string): Observable<any> {
    return this._http.get<any>(
      this._apiUrl + `googleSheets/spreadsheets/${id}/${sheetId}/columnHeaders`
    );
  }

  connectAmoCRM(): Observable<any> {
    return this._http.get<any>(this._apiUrl + 'amocrm/connect');
  }

  getAmoCrmPipelines(): Observable<IFunnel[]> {
    return this._http.get<IFunnel[]>(this._apiUrl + 'amocrm/pipelines');
  }
  getAmoCrmPipelinesStatuses(id: string): Observable<IFunnelStatus[]> {
    return this._http.get<IFunnelStatus[]>(
      this._apiUrl + `amocrm/pipelines/${id}/statuses`
    );
  }
  getAmoCustomFields(type: 'contacts' | 'leads'): Observable<any> {
    return this._http.get<any>(this._apiUrl + `amocrm/${type}/customFields`);
  }

  generateApiKey(): Observable<IntegrationConnectInterface> {
    return this._http.post<IntegrationConnectInterface>(
      this._apiUrl + 'openapi/generateApiKey',
      {}
    );
  }

  getCampaigns(): Observable<{ count: number; campaigns: ICampaigns[] }> {
    return this._http.get<{ count: number; campaigns: ICampaigns[] }>(
      this._apiUrl + `zvonok/campaigns`
    );
  }
}
