import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { environment as env } from '../../../environments/environment';
import { PaymentTestInterface } from '../interfaces/payment-test.interface';
import { UserPackage } from '../interfaces/user.package';
import { UserPricingInfoInterface } from '../interfaces/user.info';
import { CreateOrder } from '../interfaces/create-order.interface';
import { OrderResponseInterface } from '../interfaces/order.interface';
import { OrderHistory } from '../interfaces/order-history.interface';
import { Params } from '@angular/router';

export interface Plan {
  subscribers: number;
  price: Price;
  select?: boolean;
}

export interface Price {
  value: number;
  currency: string;
}

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  private readonly _apiUrl = `${env.apiUrl}/api/${env.apiVersion}/prices`;
  private readonly _apiUrlPayment = `${env.apiUrl}/api/${env.apiVersion}/payments`;

  constructor(private _http: HttpClient) {}

  getPricePlan(): Observable<Plan[]> {
    return this._http.get<Plan[]>(`${this._apiUrl}/plans`);
  }

  activateTrialPeriod(): Observable<PaymentTestInterface> {
    return this._http.post<PaymentTestInterface>(
      `${this._apiUrlPayment}/test`,
      {}
    );
  }

  activatePro(): Observable<PaymentTestInterface> {
    return this._http.post<PaymentTestInterface>(
      `${this._apiUrlPayment}/pay`,
      {}
    );
  }

  bill(data: any): Observable<{
    pdfUrl: string;
    invoiceId: string;
  }> {
    return this._http.post<{
      pdfUrl: string;
      invoiceId: string;
    }>(`${this._apiUrlPayment}/bill`, data);
  }

  getUserPackage(): Observable<UserPackage> {
    return this._http.get<UserPackage>(
      `${env.apiUrl}/api/v2/pricing/userPackage`
    );
  }

  getUserInfo(): Observable<UserPricingInfoInterface> {
    return this._http.get<UserPricingInfoInterface>(
      `${env.apiUrl}/api/v2/pricing/info`
    );
  }

  getOrders(params: Params): Observable<OrderHistory[]> {
    return this._http
      .get<{ content: OrderHistory[] }>(`${env.apiUrl}/api/v2/pricing/orders`, {
        params,
      })
      .pipe(map((res) => res.content));
  }

  getOrderById(id: string): Observable<OrderHistory> {
    return this._http.get<OrderHistory>(
      `${env.apiUrl}/api/v2/pricing/orders/${id}/`
    );
  }

  createOrder(order: Partial<CreateOrder>): Observable<OrderResponseInterface> {
    return this._http.post<OrderResponseInterface>(
      `${env.apiUrl}/api/v2/pricing/orders`,
      order
    );
  }

  createOrderById(
    id: string,
    type: string
  ): Observable<OrderResponseInterface> {
    return this._http.post<OrderResponseInterface>(
      `${env.apiUrl}/api/v2/pricing/orders/${id}/payment`,
      { type }
    );
  }
}
