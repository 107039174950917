import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { Analytics } from '../interfaces/analytics.interface';
import { environment } from '../../../environments/environment';
import { GenerateConnectionTokenInterface } from '../interfaces/generateConnectionToken.interface';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public url = `${environment.apiUrl}/api/v2`;

  constructor(private _http: HttpClient) {}

  getUserStatistic(params: Params): Observable<Analytics> {
    const startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const defaultParams = {
      startDate,
      endDate,
    };
    return this._http.get<Analytics>(`${this.url}/users/statistic`, {
      params: {
        ...defaultParams,
        ...params,
      },
    });
  }

  generateConnectionToken(): Observable<GenerateConnectionTokenInterface> {
    return this._http.post<GenerateConnectionTokenInterface>(
      `${environment.apiUrl}/api/v1/notifications/telegram/generateConnectionToken`,
      {}
    );
  }
}
