import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Flow,
  FlowScheme,
  UserRefUrlFlowTrigger,
} from '../interfaces/bot.interface';
import { environment as env } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { State } from '../store';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  constructor(private _http: HttpClient, private _store: Store<State>) {}

  getFlows(): Observable<Flow[]> {
    return this._http.get<Flow[]>(`${env.apiUrl}/api/${env.apiVersion}/flow/`);
  }

  deleteFlowById(id: string): Observable<any> {
    return this._http.delete(`${env.apiUrl}/api/${env.apiVersion}/flow/${id}`);
  }

  cloneFlowById(id: string, name: string, channelId: string): Observable<Flow> {
    return this._http.post<Flow>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${id}/clone`,
      { id, name },
      { params: { channelId } }
    );
  }
  generateTriggerCode(
    flow_id: string,
    body: any
  ): Observable<UserRefUrlFlowTrigger> {
    return this._http.post<UserRefUrlFlowTrigger>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${flow_id}/triggers/`,
      body
    );
  }

  updateFlow(flow: Flow): Observable<Flow> {
    return this._http.post<Flow>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/`,
      flow
    );
  }

  getFlowById(id: string): Observable<Flow> {
    return this._http.get<Flow>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${id}/data`
    );
  }

  patchDraft(id: string, draft: FlowScheme): Observable<any> {
    console.log('Send patch draft request: ' + JSON.stringify(draft, null, 2));
    return this._http.post<any>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${id}/patchDraft`,
      draft
    );
  }

  publish(id: string, flow: Flow): Observable<any> {
    return this._http.post<any>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${id}/publish`,
      flow
    );
  }

  test(id: string, user: string, targetStepId: any): Observable<any> {
    let url = `${env.apiUrl}/api/${env.apiVersion}/flow/${id}/test?userId=${user}`;
    if (targetStepId) {
      url += `&targetStepId=${targetStepId}`;
    }
    return this._http.post<any>(url, {});
  }

  getFlowSearch(code: string): Observable<any> {
    return this._http.get(`${env.apiUrl}/api/${env.apiVersion}/flow/search`, {
      params: { code },
    });
  }

  cleanUpStats(id: string): Observable<Flow> {
    return this._http.post<Flow>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${id}/cleanUpStats`,
      {}
    );
  }

  regenerateCode(id: string): Observable<Flow> {
    return this._http.post<Flow>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${id}/regenerateCode`,
      {}
    );
  }

  flowById(id: string): Observable<Flow> {
    return this._http.get<Flow>(
      `${env.apiUrl}/api/${env.apiVersion}/flow/${id}`
    );
  }
}
