import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import {
  IBroadcast,
  IBroadcastFilterRequest,
} from '../interfaces/broadcast.interface';
import { IResponseInterface } from '../interfaces/iResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  public saving$ = new BehaviorSubject(false);

  private _apiUrl = `${env.apiUrl}/api/${env.apiVersion}/broadcast/`;

  constructor(private _http: HttpClient) {}

  public getAll(
    pagination: IBroadcastFilterRequest | any,
    body: any = {}
  ): Observable<IResponseInterface<IBroadcast[]>> {
    const params = new HttpParams({ fromObject: pagination });
    return this._http.post<IResponseInterface<IBroadcast[]>>(
      this._apiUrl,
      body,
      { params }
    );
  }

  public getOne(id: string): Observable<IBroadcast> {
    return this._http.get<IBroadcast>(`${this._apiUrl}${id}`);
  }

  public createOrUpdate(data: IBroadcast): Observable<IBroadcast> {
    return this._http.put<IBroadcast>(this._apiUrl, data);
  }

  public delete(id: string | undefined): Observable<IBroadcast> {
    return this._http.delete<IBroadcast>(`${this._apiUrl}${id}`);
  }

  public clone(id: string | undefined): Observable<IBroadcast> {
    return this._http.post<IBroadcast>(`${this._apiUrl}${id}/clone`, {});
  }

  public schedule(id: string | undefined): Observable<IBroadcast> {
    return this._http.post<IBroadcast>(`${this._apiUrl}${id}/schedule`, {});
  }
}
