import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { UserEffects, userReducer, UserState } from './user';
import { appReducer, AppState } from './app/app.reducers';
import { AppEffects } from './app/app.effects';

export interface State {
  app: AppState;
  user: UserState;
}

export const reducers: ActionReducerMap<State> = {
  app: appReducer,
  user: userReducer,
};

// Meta reducers
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any, any>[] = [debug];

export const effects = [AppEffects, UserEffects];
