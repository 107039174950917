<div class="dialog">
  <div class="dialog__header">
    <div mat-dialog-close class="icon icon-close"></div>
  </div>
  <div class="dialog__img">
    <img class="dialog__img-dynamic" src="assets/images/share_bot.svg" alt="">
  </div>
  <div class="dialog__title">Поделиться ботом</div>

  <div class="dialog__content">
    <span>Публичная активная ссылка</span>

    <div class="bot-url">
      <span class="url" [matTooltip]="'Обновление приведет к деактивации текущей ссылки  (сценарий перестанет быть доступным по старой ссылке)'">{{ link }}</span>
      <div class="icon icon-arrow-path" (click)="refreshLink(data.id)"></div>
    </div>

    <div class="splitter"></div>

    <mat-slide-toggle
      [labelPosition]="'before'"
      [ngModel]="data.sharedCloningEnabled"
      (ngModelChange)="updateFlow($event)"
      color="primary">
      {{ 'Разрешить копировать сценарий бота по активной ссылке' | translate }}
    </mat-slide-toggle>
  </div>


  <div class="dialog__actions">
    <button class="btn btn-primary" (click)="copyLink()">{{ 'Скопировать ссылку' | translate }}</button>
    <button class="btn btn-secondary btn-sm" mat-dialog-close>В другой раз</button>
  </div>
</div>
