import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleCheckerGuard } from './core/guards/role-checker.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/panel/panel.module').then((m) => m.PanelModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'entry',
    loadChildren: () =>
      import('./modules/entry/entry.module').then((m) => m.EntryModule),
  },
  {
    path: 'tech-support-page',
    loadComponent: () =>
      import(
        './modules/panel/pages/tech-support-page/tech-support-page.component'
      ).then((m) => m.TechSupportPageComponent),
    canActivate: [RoleCheckerGuard],
    data: {
      role: 'BASIC_ADMIN_FUNCTIONS_PRIVILEGE',
    },
  },
  {
    path: 'tg-vk-connect',
    loadChildren: () =>
      import('./modules/tg-vk-connect/tg-vk-connect.routes').then(
        (m) => m.routes
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'change-tariff',
    loadChildren: () =>
      import('./modules/change-tariff/change-tariff.module').then(
        (m) => m.ChangeTariffModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'app',
    pathMatch: 'full',
  },
];
