import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class HandleUnrecoverableStateService {
  constructor(updates: SwUpdate) {
    updates.unrecoverable.subscribe(event => {
      console.log('An error occurred that we cannot recover from:\n' + event.reason + '\n\nPlease reload the page.')

    });
  }
}
