import { createReducer, on } from '@ngrx/store';
import { UserInterface } from '../../interfaces/user.interface';
import {
  getProfileSuccess,
  getUserInfoSuccess,
  getUserInfoSuccessAction,
  getUserPackageSuccessAction,
  getUserSubscriptionSuccess,
  getUserSuccess,
  resetUserStateAction,
  updateUserFailure,
  updateUserSuccess,
} from './user.actions';
import { UserSubscription } from '../../interfaces/user-subscription';
import { ProfileInterface } from '../../interfaces/agent.interface';
import { UserPricingInfoInterface } from '../../interfaces/user.info';
import { UserPackage } from '../../interfaces/user.package';

export interface UserState {
  user: UserInterface | null;
  errors: any;
  userInfo: any | null;
  userSubscription: UserSubscription | null;
  profiles: ProfileInterface[];
  userPricingInfo: UserPricingInfoInterface | null;
  userPackage: UserPackage | null;
}

export const initialUserState: UserState = {
  user: null,
  errors: null,
  userInfo: null,
  userSubscription: null,
  profiles: [],
  userPricingInfo: null,
  userPackage: null,
};

const _userReducer = createReducer(
  initialUserState,
  on(getUserSuccess, (state, action) => ({
    ...state,
    user: action.user,
  })),
  on(updateUserFailure, (state, action) => ({
    ...state,
    errors: action.error,
  })),
  on(getUserInfoSuccess, (state, action) => ({
    ...state,
    userInfo: action.userInfo,
  })),
  on(getUserSubscriptionSuccess, (state, action) => ({
    ...state,
    userSubscription: action.userSubscription,
  })),
  on(getProfileSuccess, (state, { profiles }) => ({
    ...state,
    profiles,
  })),
  on(updateUserSuccess, (state, action) => ({
    ...state,
    user: action.user,
    userInfo: { ...state.userInfo, ...action.user },
  })),
  on(getUserInfoSuccessAction, (state, { userInfo }) => ({
    ...state,
    userPricingInfo: userInfo,
  })),
  on(getUserPackageSuccessAction, (state, { userPackage }) => ({
    ...state,
    userPackage: {
      ...userPackage,
      limits: structuredClone(userPackage.limits).reverse(),
    },
  })),
  on(resetUserStateAction, () => initialUserState)
);

export function userReducer(state: UserState | undefined, action: any) {
  return _userReducer(state, action);
}
