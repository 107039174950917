import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({providedIn: 'root'})
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, private updates: SwUpdate, private zone: NgZone) {
    if (this.updates.isEnabled) {
      const everySixHours$ = interval(2 * 60 * 1000);
      console.log('Run check new version every 2 minutes')
      everySixHours$.subscribe(async () => {
        try {
          const updateFound = await updates.checkForUpdate();
          console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }
}
