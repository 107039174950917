import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CustomFieldInterface } from '../interfaces/custom-field.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsService {
  private _apiUrl = `${environment.apiUrl}/api/${environment.apiVersion}`;

  constructor(private _http: HttpClient) {}

  getCustomFields(): Observable<CustomFieldInterface[]> {
    return this._http.get<CustomFieldInterface[]>(
      this._apiUrl + '/customFields/'
    );
  }

  crateCustomField(
    body: CustomFieldInterface
  ): Observable<CustomFieldInterface> {
    return this._http.put<CustomFieldInterface>(
      this._apiUrl + '/customFields/',
      body
    );
  }

  deleteCustomField(id: string): Observable<void> {
    return this._http.delete<void>(`${this._apiUrl}/customFields/${id}`);
  }
}
