import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getProfile,
  getProfileSuccess,
  getUser,
  getUserInfo, getUserInfoAction,
  getUserInfoFailure,
  getUserInfoSuccess, getUserInfoSuccessAction, getUserPackageAction, getUserPackageSuccessAction,
  getUserSubscription,
  getUserSubscriptionFailure,
  getUserSubscriptionSuccess,
  getUserSuccess,
  updateUser,
  updateUserFailure,
  updateUserSuccess,
} from './user.actions';

import { UserService } from '../../services/user.service';
import { UserInterface } from '../../interfaces/user.interface';
import { AgentService } from '../../services/agent.service';
import { TariffService } from '../../services/tariff.service';

@Injectable()
export class UserEffects {
  constructor(
    private _actions: Actions,
    private userService: UserService,
    private _agentService: AgentService,
    private _tariffService: TariffService,
  ) {}

  loadUser$ = createEffect(() =>
    this._actions.pipe(
      ofType(getUser),
      switchMap(() =>
        this.userService.getUserInfo().pipe(
          // @ts-ignore
          map((user) => getUserSuccess({ user })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this._actions.pipe(
      ofType(updateUser),
      switchMap((payload: { user: UserInterface }) =>
        this.userService.userUpdate(payload.user).pipe(
          map((user) => updateUserSuccess({ user })),
          catchError((err) => of(updateUserFailure({ error: err })))
        )
      )
    )
  );

  loadUserInfo$ = createEffect(() =>
    this._actions.pipe(
      ofType(getUserInfo),
      switchMap(() =>
        this.userService.getCurrentUserInfo().pipe(
          map((userInfo) => getUserInfoSuccess({ userInfo })),
          catchError((err) => of(getUserInfoFailure({ errors: err })))
        )
      )
    )
  );

  loadUserSubscription$ = createEffect(() =>
    this._actions.pipe(
      ofType(getUserSubscription),
      switchMap(() =>
        this.userService.getUserSubscription().pipe(
          map((userSubscription) =>
            getUserSubscriptionSuccess({ userSubscription })
          ),
          catchError((error) => of(getUserSubscriptionFailure({ error })))
        )
      )
    )
  );

  loadProfiles$ = createEffect(() =>
    this._actions.pipe(
      ofType(getProfile),
      switchMap(() =>
        this._agentService.getProfile().pipe(
          map((profiles) => getProfileSuccess({ profiles })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getUserInfoAction$ = createEffect(() =>
    this._actions.pipe(
      ofType(getUserInfoAction),
      switchMap(() =>
        this._tariffService.getUserInfo().pipe(
          map((userInfo) => getUserInfoSuccessAction({ userInfo })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getUserPackageAction$ = createEffect(() =>
    this._actions.pipe(
      ofType(getUserPackageAction),
      switchMap(() =>
        this._tariffService.getUserPackage().pipe(
          map((userPackage) =>
            getUserPackageSuccessAction({
              userPackage,
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
