import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PromptUpdateService {
  private newAppVersionAvailable: boolean = false;

  constructor(private swUpdate: SwUpdate, private router: Router) {
    console.log('Subscribe to new sw version update and router navigation end event')
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        console.log('Detect than new version is available. Mark for for hard refresh on next navigation event.');
        this.newAppVersionAvailable = true;
      });

    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        filter((event: any) => this.newAppVersionAvailable),
        switchMap(() => this.swUpdate.activateUpdate()),
      )
      .subscribe(() => {
        console.log('Run location reload as new version available..')
        document.location.reload();
      });
  }
}
